import Flatpickr from 'stimulus-flatpickr';
import { getLocale, getDateFormat, getDateTimeFormat } from '../helpers/functions';
// Get build-in locales in flatpickr
import FlatpickrLanguages from 'flatpickr/dist/l10n';

export default class extends Flatpickr {
  connect() {
    this.element[this.identifier] = this; // Allows use of the controller in other controllers
    this.loadCustomOptions();
    //global options
    this.config = {
      ...this.config, //spread options in case some where defined in initialize
      nextArrow: '<i class="far fa-chevron-right"></i>',
      prevArrow: '<i class="far fa-chevron-left"></i>',
      allowInput: true,
      locale: {
        ...FlatpickrLanguages[getLocale()],
        firstDayOfWeek: 1 // start week on Monday
      },
      onReady: this.clearInputButton.bind(this),
      onOpen: this.setMinMax.bind(this),
      onChange: this.focusNextInput.bind(this)
    };
    super.connect();
  }

  initialize() {
    super.initialize();

    // Set date format based on the meta tag
    this.config.dateFormat = getDateFormat().replace(/%/g, '');
    this.config.dateTimeFormat = getDateTimeFormat().replace(/%/g, ''); // Not functional, but this should be used to set the time format
  }

  // Get custom options from data attributes
  loadCustomOptions() {
    // get data-show-clear attribute from this element
    this.config.showClearButton = this.data.get('show-clear-button') || false;
    this.config.clearButtonText = this.data.get('clear-button-text') || 'Remove date';
    this.config.secondInput = document.querySelector(this.data.get('second-input')) || false;
    this.config.firstInput = document.querySelector(this.data.get('first-input')) || false;
  }

  clearInputButton(_selectedDates, _dateStr, instance) {
    if (this.config.showClearButton) {
      let clearButton = document.createElement('button');
      clearButton.classList.add('flatpickr-clear', 'qc-btn', 'qc-btn-transparent');
      clearButton.innerHTML = `<i class="far fa-trash"></i> ${this.config.clearButtonText}`;
      instance.calendarContainer.appendChild(clearButton);
      clearButton.addEventListener('click', () => {
        instance.clear();
        instance.close();
      });
    }
  }

  setMinMax(_selectedDates, _dateStr, instance) {
    instance.set('minDate', this.config.firstInput?.value || this.config.minDate);
    instance.set('maxDate', this.config.secondInput?.value || this.config.maxDate);
  }

  focusNextInput(_selectedDates, _dateStr, _instance) {
    this.config.secondInput?.focus();
  }
}
