import { Controller } from '@hotwired/stimulus';
import Mark from 'mark.js/src/vanilla';

// MarkConrtoller usage
// Add the controller to the container of both your search and result elements
// <div id="main" data-controller="mark">
// Give your search field(s) the input target AND the updateQuery action
// <input data-mark-target="input" data-action="input->mark#updateQuery">
// Finally give the elements in your results the element target to highlight them
// <div data-mark-target="element"></div>
export default class extends Controller {
  static targets = [
    "input",
    "element"
  ]

  static values = {
    query: String
  }

  connect() {}

  // Update the queryValue with the value of the input field
  updateQuery(event) {
    this.queryValue = event.target.value;
  }

  // When the input field is connected, focus it and set the queryValue
  inputTargetConnected(element) {
    element.focus();
    this.queryValue = element.value
  }

  // When the queryValue changes, update all the elementTargets
  queryValueChanged() {
    // Get all elementTargets and mark the queryValue
    this.elementTargets.forEach(element => {
      new Mark(element).mark(this.queryValue);
    });
  }

  // When an element connects, mark it
  elementTargetConnected(element) {
    new Mark(element).mark(this.queryValue);
  }

  // When an element disconnects, unmark it
  elementTargetDisconnected(element) {
    new Mark(element).unmark();
  }
}
