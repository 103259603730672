import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  static targets = ['header', 'content']
  static  values = {
    contentPath: String
  }
  connect() {
    this.getFocus()
  }

  addLoading() {
    this.element.classList.add('loading');
  }

  removeLoading() {
    this.element.classList.remove('loading');
  }

  isCollapsedState(){
    return this.element.classList.contains('collapsed') || !this.element.classList.contains('expanded')
  }

  isExpandedState(){
    return this.element.classList.contains('expanded')
  }

  toggle(_event){
    if (this.isExpandedState()) {
      this.collapse()
    } else {
      this.expand()
    }
  }
  collapse(){
    // Set this.contentTarget to its current height
    let ct = this.contentTarget
    let elm = this.element
    elm.style.height = this.contentTarget.offsetHeight + this.headerTarget.offsetHeight + 'px'
    elm.classList.add('collapsed')
    elm.classList.remove('expanded')
    this.clearFocus()
    elm.style.height = this.headerTarget.scrollHeight + 'px'
    elm.addEventListener('transitionend', () => {
      ct.style.height = null
    }, { once: true})
  }

  expand(e){
    let elm = this.element
    elm.style.height = this.headerTarget.offsetHeight + this.contentTarget.offsetHeight + 'px'
    elm.classList.add('expanded')
    elm.classList.remove('collapsed')
    this.setFocus()
    elm.addEventListener('transitionend', () => {
      elm.style.height = null
    }, { once: true})
  }

  loadContent(){
    this.contentTarget.src = this.contentPathValue
    this.contentTarget.setAttribute('src', this.contentPathValue)
  }

  toggleContent(event){
    // If the event element clicked is not the header and it or one of it's parents is an anchored element, return
    if (event.target !== this.headerTarget && event.target.closest('a')) {
      return
    }
    if (this.isCollapsedState()) {
      this.loadContent()
    } else {
      this.collapse()
    }
  }

  setFocus(){
    // Add a URL parameter "focusElements" to the URL and set it to the id of this.element
    let url = new URL(window.location)
    // Check if this.element has parents that also are a collapsible controller and set an array of their ids
    let parentIds = []
    let parent = this.element.parentElement.closest('[data-controller="collapsible"]')
    while (parent) {
      parentIds.push(parent.id)
      parent = parent.parentElement.closest('[data-controller="collapsible"]')
    }
    parentIds.push(this.element.id)
    // Set focusElements[] for each parent and finally this element.id
    parentIds.forEach((id, index) => {
      url.searchParams.set('focusElements[' + index + ']', id)
    })
    // Update the URL without adding it to the browser history
    window.history.replaceState({}, '', url)
  }

  clearFocus(){
    // Remove all URL parameters "focusElements[]" of all indexes
    let url = new URL(window.location)
    Array.from(url.searchParams.keys())
      .filter(key => key.startsWith('focusElements['))
      .forEach(key => url.searchParams.delete(key));
    // Update the URL without adding it to the browser history
    window.history.replaceState({}, '', url)
  }

  getFocus(){
    // If any of the focusElements[] params is set to this.element.id, scroll to this element with an offset of 75px and expand it
    let url = new URL(window.location)
    const focusElement = Array.from(url.searchParams.values()).find(value => value === this.element.id);
    if (focusElement) {
      this.loadContent()
      this.element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
      window.scrollBy(0, -75);
    }
  }

  disconnect() {}
}
