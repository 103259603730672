import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {}

  handleMissing(e) {
    if(e.detail.response.redirected && e.detail.response.url) {
      e.preventDefault();
      let url = e.detail.response.url
      e.detail.visit(url)
    } else if(!e.detail.response.ok){
      e.preventDefault();
      this.removeLoading()
      this.alertController.pushAlert('error', `An error occurred while loading the page: ${e.detail.response.statusText}`);
    }
  }

  get alertController(){
    return document.querySelector('[data-controller="alert"]').alert;
  }

  addLoading(e) {
    // If the fetch request is a prefetch, don't show the loader
    if(e.detail.fetchOptions.headers["X-Sec-Purpose"] === "prefetch") return;
    this.element.classList.add('loading');
  }

  removeLoading() {
    this.element.classList.remove('loading');
  }

  disconnect() {
    this.removeLoading(); // Make sure to remove to loader in case the page-back button is used
  }
}
