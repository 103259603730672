import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['input']

  submitForm(event) {
    event.preventDefault()
    this.element.classList.add('loading')
    this.inputTarget.closest('form').requestSubmit()
  }

  edit(event) {
    event.preventDefault()
    this.inputTarget.click()
  }

  delete(event) {
    event.preventDefault();

    if (confirm("Are you sure you want to delete this?")) {
      // This is a workaround to trigger a submit with the input field value set to empty
      // The parameter being empty will automatically delete the attribute in the update action
      this.inputTarget.type = "hidden";
      this.inputTarget.value = "";
      this.submitForm(event)
    }
  }
}
