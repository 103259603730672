// helper functions
// Can be imported to any controller or other files
// User `import { debounce } from '../helpers/functions';` to import functions


// Debounce function to limit the rate of function execution
// Can we called from stimulus like: debounce(this.submit.bind(this), 300);
export function debounce(func, wait) {
  let timeout;
  return function(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

// Get the locale from the metadata or return "en" if it's missing
export function getLocale() {
  const locale = document.querySelector('meta[name="locale"]').content;
  return locale || "en";
}

export function getDateFormat(){
  return document.querySelector('meta[name="date-format"]').content;
}

export function getDateTimeFormat(){
  return document.querySelector('meta[name="date-time-format"]').content;
}
