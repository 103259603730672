import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  static targets = [
    'forms', // The multiple form element showing in the spec manager
    'saveStatus',  // The element that displays the save status
    'enableWhenSaved', // The element that should be enabled when saved
    'animateStreams', // Turbo frames containing a list of frames
    'focus' // The element that should receive focus when connected
  ];

  connect() {
    this.setSaveStatusIdle();
    this.formsTargets.forEach((form) => {
      form.addEventListener('turbo:submit-start', this.updateSaveStatus.bind(this));
      form.addEventListener('turbo:submit-end', this.updateSaveStatus.bind(this));
    });
    document.addEventListener('turbo:before-stream-render', this.animateStreams.bind(this));
    // listen to turbo:http events
    document.addEventListener('turbo:before-fetch-request', this.updateSaveStatus.bind(this));
    document.addEventListener('turbo:before-fetch-response', this.updateSaveStatus.bind(this));
  }

  focusTargetConnected(element){
    element.focus();
  }

  submit(event){
    // Trigger a turbo submit for the parent form
    event.target.closest('form').requestSubmit()
  }

  animateStreams(event) {
    // If the targeted stream is in the list of onDestroyTargets
    let streamTarget = this.animateStreamsTargets.find(target => target.id === event.target.getAttribute('target'));
    if (streamTarget) {
      const fallbackToDefaultActions = event.detail.render;
      event.detail.render = function(streamElement) {
        if (streamElement.action === 'remove') {
          // Add class .fadeOutAnimation and wait until the css event is done
          streamTarget.classList.remove('slide_left_fade_out_animation', 'slide_right_fade_in_animation');
          streamTarget.classList.add('slide_left_fade_out_animation');
          streamTarget.addEventListener('animationend', function() {
            fallbackToDefaultActions(streamElement);
          });
        } else {
          fallbackToDefaultActions(streamElement);
        }
      };
    }
  }

  updateSaveStatus(event) {
    // If the event is turbo:submit-start, set the save status to loading.
    // Else if the event is turbo:submit-end, check if event.detail property was a success
    switch (event.type) {
      case 'turbo:submit-start':
      case 'turbo:before-fetch-request':
        if(event?.detail?.fetchOptions?.method === 'GET') break;
        this.setSaveStatusLoading();
        break;
      case 'turbo:submit-end':
        if (event.detail.success) {
          this.setSaveStatusIdle();
        } else {
          this.setSaveStatusError();
        }
        break;
      case 'turbo:before-fetch-response':
        if (!event?.detail?.fetchResponse?.response?.ok) {
          this.setSaveStatusError();
        }else {
          this.setSaveStatusIdle();
        }
        break;
    }
  }

  setSaveStatusIdle() {
    this.saveStatusTarget.classList.remove('loading', 'error');
    this.saveStatusTarget.classList.add('idle');
    this.enableButtons()
  }

  setSaveStatusError() {
    this.saveStatusTarget.classList.remove('loading', 'idle');
    this.saveStatusTarget.classList.add('error');
    this.disableButtons()
  }

  setSaveStatusLoading() {
    this.saveStatusTarget.classList.remove('idle', 'error');
    this.saveStatusTarget.classList.add('loading');
    this.disableButtons()
  }

  disableButtons(){
    this.enableWhenSavedTargets.forEach((element) => {
      element.classList.add('disabled');
    });
  }

  enableButtons(){
    this.enableWhenSavedTargets.forEach((element) => {
      element.classList.remove('disabled');
    });
  }

  disconnect() {
    super.disconnect();
    this.formsTargets.forEach((form) => {
      form.removeEventListener('turbo:submit-start', this.updateSaveStatus.bind(this));
      form.removeEventListener('turbo:submit-end', this.updateSaveStatus.bind(this));
    });
    document.removeEventListener('turbo:before-stream-render', this.animateStreams.bind(this));
  }
}
