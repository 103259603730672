import { Controller } from '@hotwired/stimulus';
import { debounce } from '../helpers/functions';

// Orderable controller is a controller to allow for dynamic ordering content
// You add 2 hidden input fields to the form, set one target to the orderByTarget and the other to the orderDirectionTarget
// These 2 fields are used to store the order field and direction so the form can be submitted as normal
// Then you add a data-action="click->orderable#toggleOrder" to the  elements that should toggle the order
// You can add params to the action to set the field and direction
// <a data-action="click->orderable#toggleOrder" data-orderable-field-param="name" data-orderable-direction-param="asc">Name</a>

export default class extends Controller {
  static targets = [
    'orderBy',
    'orderDirection',
    'toggler'
  ];

  connect() {
  }

  toggleOrder({ params: { field, direction } }) {
    console.log(field, direction);
    if (this.getOrderField() === field) {
      this.setOrderAscending(!this.orderAscendingValue());
    } else {
      this.setOrderField(field);
      this.setOrderAscending(direction.toLowerCase() === 'asc');
    }
    // Trigger a change event in orderDirectionTarget
    this.orderDirectionTarget.dispatchEvent(new Event('change'));
  }

  togglerTargetConnected(target) {
    // If the this.orderFieldValue equals the target[data-orderable-field-param] then add the class sort_active
    // Then set the class sort_asc or sort_desc based on the this.orderAscendingValue
    if (this.getOrderField() === target.dataset.orderableFieldParam) {
      target.classList.add('sort_active');
      target.classList.add(this.orderAscendingValue() ? 'sort_asc' : 'sort_desc');
    }
  }

  getOrderField() {
    return this.orderByTarget.value;
  }

  setOrderField(value) {
    this.orderByTarget.value = value;
  }

  getOrderDirection() {
    return this.orderDirectionTarget.value.toLowerCase() === 'asc' ? 'asc' : 'desc';
  }

  setOrderAscending(value) {
    this.orderDirectionTarget.value = value ? 'asc' : 'desc';
  }

  orderAscendingValue() {
    return this.orderDirectionTarget.value.toLowerCase() === 'asc';
  }
}
